<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="6">
            <b-form-group id="input-game" label-for="Product">
              <label>Product: <span class="text-danger">*</span> </label>
              <b-form-select
                v-model="selectedProduct"
                :options="allProductAdsList"
                name="game"
                data-vv-as="Product"
                :class="{ input: true, 'is-danger': errors.has('game') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Product--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Product--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('game')" class="is-danger-text position-relative">{{
                errors.first('game')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Title -->
          <b-col cols="6">
            <b-form-group id="input-title">
              <label for="rewardInput">Title:</label>
              <b-form-input
                id="titleInput"
                trim
                name="title"
                v-model="editedItem.title"
                type="text"
                min="0"
                aria-describedby="input-title"
                placeholder="Title..."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Note -->
          <b-col cols="6">
            <b-form-group id="input-note">
              <label for="rewardInput">Note:</label>
              <b-form-input
                id="noteInput"
                trim
                name="note"
                v-model="editedItem.note"
                type="text"
                min="0"
                aria-describedby="input-note"
                placeholder="Note..."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Reward -->
          <b-col cols="6">
            <b-form-group id="input-reward">
              <label for="rewardInput">Reward: <span class="text-danger">*</span></label>
              <b-form-input
                id="rewardInput"
                trim
                name="reward"
                v-model="editedItem.reward"
                type="number"
                min="0"
                aria-describedby="input-reward"
                placeholder="Reward..."
                data-vv-as="Reward"
                :class="{ input: true, 'is-danger': errors.has('reward') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('reward')" class="is-danger-text position-relative">{{
                errors.first('reward')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Jackpott -->
          <b-col cols="4">
            <b-form-group id="input-jackpott">
              <label for="jackpott">Jackpott:</label>
              <b-form-input
                trim
                id="jackpott"
                v-model="editedItem.jackpott"
                type="number"
                min="0"
                aria-describedby="input-jackpott"
                placeholder="Jackpott..."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Correct Slots -->
          <b-col cols="4">
            <b-form-group id="input-correct-slot">
              <label for="correctSlotInput">Correct Slot: <span class="text-danger">*</span></label>
              <b-form-input
                trim
                id="correctSlotInput"
                name="correctSlot"
                v-model="editedItem.correct_slots"
                type="number"
                min="0"
                aria-describedby="input-correct-slot"
                placeholder="Correct Slot..."
                data-vv-as="Reward"
                :class="{ input: true, 'is-danger': errors.has('correctSlot') }"
                v-validate="{ required: true, numeric: true }"
              ></b-form-input>
              <span v-show="errors.has('correctSlot')" class="is-danger-text position-relative">{{
                errors.first('correctSlot')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- status -->
          <b-col cols="4">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select v-model="filter_status" :options="statusList" name="Status">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Product Reward</h4>
      </b-col>
      <!-- FilterName -->
      <!-- <b-col cols="4">
        <b-form-group
          id="input-name-filter"
          label="Product"
          label-for="name-filter"
        >
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search articles ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col> -->
      <!-- FilterCategori -->
      <b-col cols="3">
        <b-form-group id="input-screen-filter" label="Product" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_game" :options="allProductAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col cols="3">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="6" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(reward)="item">
        {{ formatPrice(item.item.reward) }}
      </template>
      <template v-slot:cell(jackpott)="item">
        {{ formatPrice(item.item.jackpott) }}
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'screenAds',
  components: {},
  data() {
    return {
      // isCreated: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_game: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Title', key: 'title' },
        { text: 'Product', key: 'product' },
        { text: 'Reward', key: 'reward' },
        { text: 'Jackpott', key: 'jackpott' },
        { text: 'Correct Slots', key: 'correct_slots' },
        { text: 'Note', key: 'note' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allProductAdsList: [],
      selectedProduct: null,
      modalTitle: '',
      //Pagination
      rows: 0,
      perPage: 10,
      currentPage: 1,
      // errors: [],
      editedItem: {
        id: '',
        product_id: '',
        title: '',
        correct_slots: '',
        reward: '',
        status: '',
        jackpott: '',
        product: '',
        note: ''
      },
      dialog: false,
      editedIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('reward') || !this.editedItem.reward || !this.selectedProduct || !this.editedItem.correct_slots
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // {
      //   title: "Product",
      //   route: "product"
      // },
      { title: 'Product Reward' }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsProduct = ApiService.get(
        'product-rewards',
        `?name=${this.filter_name}&product_id=${this.filter_game || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const allProduct = ApiService.get('allproduct');
      axios
        .all([adsProduct, allProduct])
        .then(
          axios.spread((...response) => {
            const adsProductRes = response[0];
            const allProductRes = response[1];
            this.items = adsProductRes.data.data.productRewardList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = adsProductRes.data.data.total;
            this.allProductAdsList = allProductRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          if (errors) {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            this.isBusy = false;
          }
        });
    },
    addItem: function () {
      // this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Product Reward';
    },
    editItem: function (item) {
      // this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Product Reward';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedProduct = item.product_id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`product-rewards/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Product deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        product_id: '',
        title: '',
        correct_slots: '',
        reward: '',
        status: '',
        jackpott: '',
        product: '',
        note: ''
      };
      this.editedIndex = -1;
      this.selectedProduct = null;
      this.invalidSlotCountMessage = '';
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      this.editedItem.product_id = this.selectedProduct;
      let productData = {
        product_id: this.editedItem.product_id,
        correct_slots: parseInt(this.editedItem.correct_slots),
        reward: +parseFloat(this.editedItem.reward).toFixed(2),
        status: this.filter_status,
        jackpott: parseInt(this.editedItem.jackpott) || undefined,
        title: this.editedItem.title || undefined,
        note: this.editedItem.note || undefined
      };
      if (this.editedIndex > -1) {
        // Update product
        axios({
          method: 'PUT',
          url: `product-rewards/${this.items[this.editedIndex].id}`,
          data: productData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              //update game
              this.allProductAdsList.some((product) => {
                if (product.value == this.selectedProduct) {
                  this.editedItem.product = product.text;
                  this.editedItem.product_id = product.value;
                  return true;
                }
              });
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Product Reward updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        // Add product
        axios({
          method: 'POST',
          url: 'product-rewards',
          data: productData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data
              });
              this.close();
              this.$bvToast.toast('Product Reward added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
